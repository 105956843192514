@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

html {
    overflow: hidden;
    width: 100%;
}

body {
    display: flex;
    width: 100%;
    position: fixed;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: #1d1e25;
}

*,
*::before,
*::after {
    user-select: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

* {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

textarea {
    resize: none;
}

select {
    appearance: none;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    appearance: none;
}

button:focus,
select:focus,
textarea:focus,
input:focus {
    outline: none;
    @apply focus:ring-0 focus:ring-offset-0;
}

select:disabled {
    opacity: 1;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #011e3d2f;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #011e3d2f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #011e3d5f;
}

#root {
    font-family: 'Sofia Pro', sans-serif;
    background-color: #181920;
    color: #616473;
    font-weight: 300;
    position: relative;
    overflow-y: auto;
}

.desktop-wrapper {
    position: relative;
    width: 450px !important;
    margin: 0px auto !important;
}

@media only screen and (min-width: 480px) {
    body {
        padding: 24px 0;
    }

    #root {
        height: calc(100vh - 48px) !important;
    }

    #menu {
        max-width: 450px;
        margin: 0px auto;
        bottom: 24px;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
    }

    #modal {
        max-width: 450px;
        margin: 24px auto;
        border-radius: 16px;
    }

    .desktop-wrapper {
        border-radius: 16px;
        overflow: auto;
    }
}
